@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200;300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@font-face {
  font-family: "Helvetica"; /*Can be any text*/
  src: local("Helvetica Neu Bold"),
    url("./fonts/Helvetica Neu Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Helvetica Medium"; /*Can be any text*/
  src: local("HelveticaNeue Medium"),
    url("./fonts/HelveticaNeue Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Helvetica Regular"; /*Can be any text*/
  src: local("HelveticaNeue Thin"),
    url("./fonts/HelveticaNeue Thin.ttf") format("truetype");
}

html {
  scroll-behavior: initial;
}
html,
body {
  width: 100%;
  min-height: 100%;
}
body {
  margin: 0;
  transition: 0.3s ease-in;
}
a:hover {
  text-decoration: none !important;
}
h1,
h2 {
  font-family: "Helvetica";
}
.header {
  position: relative;
  width: calc(100% - 32px);
  padding: 0px 0px;
  margin: 16px;
  /* top: 16px; */
  z-index: 999;
}
.f-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3000;
}
.changing-text {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 120%;
  letter-spacing: -0.05em;
  color: #1b1b1b;
  margin-bottom: 0px;
  text-align: center;
  transform: translateY(30px);
}
.loading-text.display-text:after {
  content: "";
  animation: changingText 2s ease-in infinite;
}
.logo-img {
  width: 60px;
  height: 60px;
  cursor: pointer;
}
@keyframes changingText {
  0% {
    content: "Hello";
  }
  10% {
    content: "Noor ul Huda Khan";
  }
  20% {
    content: "Architect";
  }
  30% {
    content: "Visualiser";
  }
  40% {
    content: "Vectors";
  }
  50% {
    content: "3D Modeling";
  }
  60% {
    content: "Revit";
  }
  70% {
    content: "Freelance";
  }
  80% {
    content: "📍 Lahore";
  }
  90% {
    content: "Architecture";
  }
  100% {
    content: "See you soon";
  }
}
.loading-container-2 {
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2000;
  pointer-events: none;
}
.loading-container {
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2000;
  pointer-events: none;
  transform: translateY(120%);
  /* transition: transform 3s ease-out 0s; */
}
.loading-text {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 120%;
  letter-spacing: -0.05em;
  color: #1b1b1b;
  margin-bottom: 0px;
  text-align: center;
}
.rounded-wrap {
  height: 10vh;
  width: 100%;
  z-index: 2000;
  position: absolute;
  overflow: hidden;
}
.rounded-div {
  width: 150%;
  content: "";
  display: block;
  position: absolute;
  /* background-color: #078585; */
  background-color: white;
  height: 750%;
  left: 50%;
  border-radius: 50%;
  transform: translate(-50%, -86.666%);
  z-index: 1;
}
.top-wrap {
  transform: translateY(-99%) scale(1, -1);
  top: 0;
}
.bottom-wrap {
  transform: translateY(99%) scale(-1, 1);
  bottom: 0;
}
.loading-screen {
  /* background-color: #078585; */
  background-color: white;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading-container-2.open-container {
  /* transform: translateY(-110%); */
  animation: mymove2 2.4s ease-out;
  opacity: 1;
}
.close-animation-2 {
  z-index: -1;
  display: none;
}
.loading-container.open-container {
  /* transform: translateY(-110%); */
  animation: mymove 2.7s ease-out;
  opacity: 1;
}
@keyframes mymove {
  0% {
    transform: translateY(120%);
  }
  20% {
    transform: translateY(0%);
  }
  90% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-110%);
  }
}
@keyframes mymove2 {
  0% {
    transform: translateY(0%);
  }
  90% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-110%);
  }
}
.email-result {
  font-size: 32px;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.05em;
  color: #1b1b1b;
  margin-bottom: 30px;
  text-align: center;
}
.email-res-center {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.nav-circle {
  background-color: white;
  height: 64px;
  width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: fixed;
  cursor: pointer;
  right: 20px;
  top: 20px;
  z-index: 997;
  box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.08);
}
.menu-image {
  width: 20px;
  height: 20px;
}
.nav-revit {
  background-color: #ffffff;
}
.nav-visualisation {
  background-color: #d9e7ca;
}
.nav-vector {
  background-color: #ffdbcf;
}
.navbar-main {
  position: relative;
  z-index: 998;
  width: 100%;
  border-radius: 35px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.3s;
}
.navbar-logo {
  height: 40px;
  width: 40px;
}
.navbar-links {
  display: flex;
  align-items: center;
}
.navbar-links .link-item {
  cursor: pointer;
  font-size: 14px;
  font-family: "Roboto Mono", monospace;
  color: #1b1b1b;
  width: max-content;
  font-weight: 400;
  margin: 0px 10px;
  letter-spacing: -0.2px;
  padding: 5px 12px;
  border-radius: 15px;
  transition: 0.3s;
  border: 1px solid transparent;
}
.navbar-links .visualisation-active-link {
  background-color: #b5d099;
  border: 1px solid #b5d099;
}
.navbar-links .link-item:hover {
  border: 1px solid #1b1b1b;
}
.navbar-links .vectors-active-link {
  background-color: #efb6a3;
  border: 1px solid #efb6a3;
}
.side-drawer {
  height: 100%;
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  max-width: 400px;
  z-index: 1996;
  transform: translateX(100%);
  transition: transform 0.3s ease-out 0s;
  background-color: rgb(255 255 255 / 60%);
}
.side-drawer.open {
  transform: translateX(0px);
  box-shadow: rgb(0 0 0 / 43%) 2px 0px 20px;
}
.side-drawer ul {
  display: flex;
  flex-direction: column;
  list-style: none;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0px;
}
.side-drawer li {
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 30px;
}
.side-drawer .side-drawer-links {
  text-decoration: none;
  transition: 0.3s;
  color: #1b1b1b;
  font-size: 16px;
  font-family: "Helvetica Medium";
  font-style: normal;
  border: 1px solid transparent;
  width: 240px;
  padding: 10px 15px;
  border-radius: 20px;
  margin: 4px 0px;
}
.side-drawer .side-drawer-links.active-class {
  border: 1px solid #1b1b1b;
}
.side-drawer .side-drawer-links:hover {
  opacity: 1;
}
.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 999;
  top: 0;
  left: 0;
}
.toggle-button {
  outline: none;
  border: none;
  background: none;
}

.main-section {
  transform: translateY(1000px);
}
.home-banner-section {
  background-color: #ffffff;
  border-radius: 100px;
  padding: 90px 0px;
  margin: 0px 16px;
}
.home-text {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.banner-hi {
  color: #1b1b1b;
  font-family: "Roboto Mono", monospace;
  font-weight: 400;
  letter-spacing: -0.04em;
  margin-bottom: 8px;
  text-align: left;
  font-size: 16px;
}
.banner-heading {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 500;
  font-size: 64px;
  line-height: 120%;
  letter-spacing: -0.05em;
  color: #1b1b1b;
  margin-bottom: 0px;
  text-align: left;
}
.banner-description {
  margin-bottom: 32px;
}
.banner-image {
  width: 80%;
  max-width: 450px;
  position: relative;
  z-index: 2;
}
.back-image {
  width: 80%;
  max-width: 450px;
  z-index: 1;
  position: absolute;
}
.section-heading {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 120%;
  letter-spacing: -0.05em;
  color: #1b1b1b;
  margin-bottom: 0px;
  text-align: left;
}
.main-btn {
  background: #95e2e2;
  border: 2px solid #95e2e2;
  border-radius: 27.5px;
  padding: 16px;
  color: #1b1b1b;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.04em;
  width: 135px;
  text-align: center;
  cursor: pointer;
  transition: 0.3s;
}
.main-btn:hover {
  background-color: white;
  border: 2px solid #1b1b1b;
  color: inherit;
}
.center-image {
  display: flex;
  align-items: center;
  justify-content: center;
}
.end-image {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.services-section {
  background-color: #ffffff;
  border-radius: 100px;
  margin: 16px 16px 8px 16px;
}
.contact-section {
  background-color: #ffffff;
  border-radius: 100px;
  margin: 16px 16px 8px 16px;
  padding: 128px 0px;
}
.service-adj {
  padding: 64px 0px;
}
.expo-img {
  width: 205px;
  height: 100%;
}
.services {
  padding-top: 8px;
  margin-bottom: 8px;
}
.services .icon {
  width: 64px;
  height: 64px;
  margin-bottom: 24px;
}
.services .heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.04em;
  color: #1b1b1b;
  margin-bottom: 16px;
  text-align: left;
}
.services .description {
  text-align: left;
  font-family: "Roboto Mono", monospace;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #1b1b1b;
  opacity: 0.4;
  width: 80%;
  margin-bottom: 0px;
}

.work-section {
  background-color: #ffffff;
  border-radius: 100px;
  padding-top: 128px;
  margin: 16px 16px 0px 16px;
}
.work {
  padding-top: 110px;
  border-top-left-radius: 90px;
  border-top-right-radius: 90px;
  position: relative;
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.vector-bg {
  background-color: #ffdad9;
  transition: 0.3s;
}
.visualization-bg {
  background-color: #d9e7cb;
  transition: 0.3s;
}
.revit-bg {
  background-color: #e6e4bf;
  transition: 0.3s;
}
.vector-bg:hover {
  background-color: #fbc0be;
  background-image: url("./images/texture-red-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  /* border-top-left-radius: 140px;
  border-top-right-radius: 140px; */
}
.visualization-bg:hover {
  background-color: #cce9af;
  background-image: url("./images/textured-green-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.revit-bg:hover {
  background-color: #e8e5ae;
  background-image: url("./images/texture-yellow-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.work .heading {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.05em;
  color: #1b1b1b;
  margin-bottom: 106px;
}
.work .heading .bar {
  width: 0px;
  height: 2px;
  border-radius: 2px;
  background-color: #1b1b1b;
  margin-top: 8px;
  display: block;
  transition: 0.5s;
}
.work .work-image-container {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.work .work-image-container .work-image {
  width: 100%;
}
.work:hover {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.work:hover .heading .bar {
  width: 100%;
}
.input-class {
  background: #bce4e4;
  border-radius: 60px;
  padding: 50px;
  border: 2px solid #bce4e4;
  outline-color: #bce4e4;
  width: 100%;
  font-family: "Helvetica Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #181818;
  margin-bottom: 16px;
  transition: 0.3s;
}
.input-class:focus-visible {
  outline-color: #32c6c6;
}
.text-area {
  height: 350px !important;
  border-radius: 80px;
}
.input-class:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #bce4e4 inset;
  box-shadow: 0 0 0px 1000px #bce4e4 inset;
  transition: background-color 5000s ease-in-out 0s;
}
.send-btn {
  background: #bce4e4;
  font-family: "Helvetica Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #181818;
  width: 100%;
  border: none;
  transition: 0.3s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.send-btn:hover {
  background-color: #95e2e2;
}

.footer-class {
  background-color: #ffffff;
  border-radius: 100px;
  padding: 80px 104px 50px 104px;
  margin: 16px;
}
.footer-link {
  background-color: inherit;
  border-radius: 40px;
  width: 100%;
  display: flex;
  padding: 22px;
  text-align: center;
  justify-content: center;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1b1b1b;
  border: 1px solid #1b1b1b;
  transition: 0.3s;
  cursor: pointer;
  letter-spacing: -0.5px;
}
.footer-link:hover {
  /* background-color: #b0f7f7;
  text-decoration: none; */
  color: #1b1b1b;
  letter-spacing: 1.5px;
}
.footer-text {
  font-family: "Helvetica Medium";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0px;
  color: #181818;
  text-align: left;
}
.center-text {
  text-align: center;
}
.right-text {
  text-align: right;
}
.projects-banner {
  border-radius: 40px;
  margin: 0px 16px;
}
.visual-banner {
  background-color: #f1f3e8;
}
.vector-banner {
  background-color: #ffe8e8;
}
.revit-banner {
  background-color: #f4f1e4;
}
.visual-banner-container {
  height: 100%;
  overflow: hidden;
  border-radius: 40px;
}
.visual-banner-container > .visual-banner-img {
  width: 100%;
  height: 650px;
  object-fit: cover;
  object-position: top;
}

.visual-heading {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 500;
  font-size: 64px;
  line-height: 120%;
  letter-spacing: -0.05em;
  color: #1b1b1b;
  margin-bottom: 16px;
  text-align: left;
}
.visual-paragraph {
  color: #1b1b1b;
  font-family: "Roboto Mono", monospace;
  font-weight: 300;
  letter-spacing: -0.04em;
  margin-bottom: 0px;
  text-align: left;
  font-size: 16px;
  width: 90%;
}
.visual-text {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  padding: 50px 0px;
  height: 100%;
}
.projects-section {
  padding-top: 128px;
}
.project-image {
  width: 100%;
  margin: 8px 0px;
  background-color: white;
  border-radius: 20px;
}
.project-image .image-style {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  min-height: 400px;
}
.project-heading-div {
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.project-heading {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 120%;
  letter-spacing: -0.05em;
  color: #1b1b1b;
  margin-bottom: 0px;
  text-align: left;
  width: calc(100% - 60px);
}
.project-description {
  margin-top: 16px;
  font-family: "Roboto Mono", monospace;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: -0.05em;
  color: #1b1b1b;
  margin-bottom: 16px;
  text-align: left;
}
.card-box {
  border-radius: 20px;
  padding: 0px;
  transition: 0.3s;
  cursor: pointer;
  margin-bottom: 16px;
}
.card-visualisation-box {
  background-color: #f1f3e8;
  border: 2px solid #d9e7cb;
}
.card-visualisation-box:hover {
  background-color: #d9e7cb;
}
.card-revit-box {
  background-color: #f7f0d2;
  border: 2px solid #f4f1e4;
}
.card-revit-box:hover {
  background-color: #f4f1e4;
}
.card-vector-box {
  background-color: #ffe8e8;
  border: 2px solid #ffdbcf;
}
.card-vector-box:hover {
  background-color: #ffdbcf;
}
.card-box .card-container {
  border-radius: 20px;
  margin-bottom: 16px;
  overflow: hidden;
  height: 400px;
}
.card-box .card-container .image {
  width: 100%;
  transition: 0.3s;
  object-fit: cover;
  object-position: center;
  height: 100%;
}
.card-box .card-padding {
  padding: 16px 32px 32px 32px;
}
.card-box .card-box-distribution {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.card-box .title {
  font-family: "Helvetica Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.25px;
  color: #181818;
  margin-bottom: 8px;
  text-align: left;
}
.card-box .description {
  font-family: "Helvetica Regular";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #181818;
  margin-bottom: 0px;
  text-align: left;
}

.card-box:hover {
  transform: scale(0.95);
}
.overlay-blur {
  backdrop-filter: blur(14px) !important;
  -webkit-backdrop-filter: blur(14px) !important;
}
.overlay-green {
  background-color: rgba(253, 253, 246, 0.7);
}
.overlay-red {
  background-color: rgba(255, 237, 237, 0.7);
}
.overlay-yellow {
  background-color: rgb(253, 250, 233, 0.7);
}
.overlay {
  position: fixed;
  width: 70%;
  height: 100vh;
  top: 0;
  right: 0px;
  z-index: 1000;
  transform: translateX(100%);
}
.overlay.add-animation {
  transform: translateX(0px);
  transition: transform 0.3s ease-out 0s;
}
.overlay.remove-animation {
  transform: translateX(100%);
  transition: transform 0.3s ease-in 0s;
}
.overlay-container {
  height: 40%;
  width: 100%;
  overflow: hidden;
  object-fit: cover;
  object-position: center;
  border-radius: 40px;
}
.overlay-backdrop {
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: 0.3s ease-in;
}
.circle {
  background-color: white;
  height: 64px;
  width: 64px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.08);
  cursor: pointer;
}
.circle-mini {
  background-color: white;
  height: 56px;
  width: 56px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  box-shadow: 0px 0px 18px 4px rgb(0 0 0 / 8%);
  -webkit-box-shadow: 0px 0px 18px 4px rgb(0 0 0 / 8%);
  -moz-box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.08);
  cursor: pointer;
}
.green-backdrop {
  background-color: rgba(253, 253, 246, 0.1);
}
.yellow-backdrop {
  background-color: rgb(253, 250, 233, 0.1);
}
.red-backdrop {
  background-color: rgba(255, 237, 237, 0.1);
}
.overlay-backdrop.open-animation {
  backdrop-filter: blur(14px) !important;
  opacity: 1;
  z-index: 999;
}
.overlay-backdrop.close-animation {
  z-index: -1;
  opacity: 1;
}

.circle .icon {
  width: 20px;
  height: 20px;
}
.left {
  top: 50%;
  left: 35px;
}
.right {
  top: 50%;
  right: 35px;
}
.close-btn {
  top: 25px;
  right: 25px;
}
.close-btn-position {
  top: 40px;
  left: 20px;
}
.card-box .view-more-btn {
  border: 1px solid white;
  /* background-color: #d9e7cb; */
  padding: 12px 18px 12px 24px;
  font-size: 16px;
  border-radius: 30px;
  display: flex;
  font-weight: 500;
  font-family: "Helvetica Medium";
  font-style: normal;
}
.card-box .btn-revit {
  background-color: #e6e4bf;
}
.service-mb {
  margin-top: 8px;
}
.service-box {
  border-radius: 70px;
  padding: 75px;
  height: 100%;
}
.vertical-center {
  display: flex;
  align-items: center;
}
.center-all {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.left-box {
  transform: translateX(50%);
  /* background-color: #6cb2b2; */
  background-color: white;
  z-index: 2;
  position: relative;
}
.left-mini-card {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding-left: 40px;
}
.left-mini-card .mini-heading {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: #1b1b1b;
  margin-bottom: 32px;
  text-align: left;
}
.left-mini-card .mini-description {
  font-family: "Roboto Mono", monospace;
  font-weight: 300;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: #1b1b1b;
  margin-bottom: 0px;
  text-align: left;
}
.right-box {
  transform: translateX(-50%);
  /* background-color: #bce4e4; */
  background-color: white;
  z-index: 2;
  position: relative;
  box-shadow: -5px 0px 30px 1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: -5px 0px 30px 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -5px 0px 30px 1px rgba(0, 0, 0, 0.1);
}
.extension {
  position: absolute;
  right: 100px;
  /* background-color: #6cb2b2; */
  background-color: white;
  height: 100%;
  width: 55%;
  z-index: 1;
  top: 0px;
  opacity: 0;
}
.service-box .icon {
  width: 60px;
  height: 60px;
}
.service-box .sub-heading {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #1b1b1b;
  text-align: center;
  letter-spacing: -0.4px;
  margin-bottom: 16px;
}
.service-box .heading {
  font-family: "Helvetica";
  font-weight: 500;
  font-size: 40px;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #1b1b1b;
  text-align: center;
  margin-bottom: 0px;
}
.detail-section {
  background-color: #ffffff;
  border-radius: 100px;
  padding: 90px 0px;
  margin: 16px;
}
.detail-page-heading {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 500;
  font-size: 56px;
  line-height: 120%;
  letter-spacing: -0.05em;
  color: #1b1b1b;
  margin-bottom: 32px;
  text-align: center;
}
.detail-page-category {
  font-family: "Roboto Mono", monospace;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: -0.05em;
  color: #1b1b1b;
  margin-bottom: 16px;
  text-align: center;
  border: 1px solid #1b1b1b;
  border-radius: 20px;
  padding: 6px 10px;
  cursor: pointer;
}
.detail-page-description {
  font-family: "Roboto Mono", monospace;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -2px;
  color: #1b1b1b;
  margin-bottom: 32px;
  text-align: center;
}
.detail-visualisation {
  transition: 0.3s;
  background-color: white;
}
.detail-visualisation:hover {
  background-color: rgb(181, 208, 153);
  color: #1b1b1b;
}
.detail-img {
  border-radius: 30px;
  width: 100%;
  margin: 8px 0px;
}
.colour-box {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.colour-box .colour-circle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin: 0px 2px;
}
.vectors-1 {
  background-color: rgb(239, 182, 163);
}
.vectors-2 {
  background-color: rgb(255, 219, 207);
}
.vectors-3 {
  background-color: #ffe8e8;
}
.vectors-4 {
  background-color: rgb(255, 245, 245);
}

.visualisation-1 {
  background-color: rgb(181, 208, 153);
}
.visualisation-2 {
  background-color: rgb(217, 231, 202);
}
.visualisation-3 {
  background-color: #f1f3e8;
}
.visualisation-4 {
  background-color: rgb(253, 253, 246);
}

.revit-1 {
  background-color: rgb(200, 198, 161);
}
.revit-2 {
  background-color: rgb(230, 228, 191);
}
.revit-3 {
  background-color: #f4f1e4;
}
.revit-4 {
  background-color: rgb(255, 252, 243);
}
.about-img {
  width: 100%;
  position: sticky;
  top: 0;
}
.about-text {
  font-family: "Helvetica Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 180%;
  color: #1b1b1b;
  margin-bottom: 32px;
  text-align: left;
}
.hand-shake {
  animation: handShake 2s ease-in infinite;
  margin-left: 10px;
}
@keyframes handShake {
  0% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(30deg);
  }
  80% {
    transform: rotate(10deg);
  }
  90% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
/* /
/
/
/
/
/
/
/ */
/* //Media Queries */

@media (max-width: 820px) and (min-width: 540px) {
  .home-banner-section {
    padding: 60px 40px;
    border-radius: 50px;
  }
  .work-section {
    padding: 60px 20px 20px 20px;
    border-radius: 50px;
  }
  .left-box {
    transform: translateX(0px) !important;
  }
  .extension {
    display: none;
  }
  .right-box {
    display: none;
  }
  .work {
    padding-top: 60px;
    border-radius: 50px;
    margin-top: 16px;
  }
  .services-section {
    padding: 60px 40px;
    border-radius: 50px;
    margin: 16px 0px 8px 0px;
  }
  .service-adj {
    padding: 0px;
  }
  .service-box {
    border-radius: 50px;
  }
  .contact-section {
    padding: 64px 40px;
    border-radius: 50px;
  }
  .input-class {
    padding: 30px;
  }
  .text-area {
    height: 250px !important;
    border-radius: 40px;
  }
  .footer-class {
    padding: 60px 50px 50px 50px;
    border-radius: 50px;
  }
  .footer-link {
    margin: 8px 0px;
  }
  .footer-text {
    text-align: center;
  }

  .visual-text {
    padding: 50px 40px;
  }
  .visual-paragraph {
    margin-bottom: 16px;
    width: 100%;
  }
  .projects-section {
    padding-top: 64px;
  }
  .overlay {
    width: 80%;
  }
  .detail-section {
    border-radius: 50px;
    padding: 60px 40px;
  }
  .about-box {
    margin-top: 50px;
  }
}

@media (max-width: 420px) {
  .header {
    display: none;
  }
  .home-banner-section {
    border-radius: 30px;
    margin-top: 20px;
  }
  .work-section {
    border-radius: 40px;
    padding-top: 64px;
  }
  .banner-heading {
    font-size: 48px;
  }
  .banner-description {
    text-align: left !important;
  }
  .banner-hi,
  .section-heading {
    text-align: center;
  }
  .main-btn {
    margin-bottom: 30px;
    width: 100%;
  }
  .section-heading {
    font-size: 32px;
  }
  .service-adj {
    padding: 64px 16px;
  }
  .work {
    border-radius: 30px;
    margin: 8px 0px;
    padding-top: 64px;
  }
  .work .work-image-container .work-image {
    border-radius: 30px;
  }
  .services-section {
    border-radius: 40px;
    padding-bottom: 64px;
    margin: 16px 0px 8px 0px;
  }
  .left-box {
    transform: translateX(0px) !important;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .service-box {
    padding: 40px 20px 40px 20px;
  }
  .left-mini-card {
    padding-left: 0px;
  }
  .left-mini-card .mini-heading {
    text-align: center;
    margin-bottom: 16px;
    width: 100%;
    margin-top: 8px;
  }
  .left-mini-card .mini-description {
    font-size: 14px;
  }
  .right-box {
    display: none;
  }
  .extension {
    display: none;
  }
  .service-box {
    border-radius: 40px;
  }
  .service-box .sub-heading {
    font-size: 14px;
    font-weight: 400;
  }
  .service-box .heading {
    font-size: 32px;
  }
  .contact-section {
    border-radius: 40px;
    padding: 64px 0px 0px 0px;
    margin-top: 8px;
  }
  .input-class {
    border-radius: 40px;
    padding: 20px;
    border-radius: 10px;
    font-size: 16px;
  }
  .text-area {
    border-radius: 20px;
    margin-bottom: 16px;
  }
  .footer-class {
    border-radius: 40px;
    padding: 48px 32px 32px 32px;
  }
  .footer-link {
    margin: 8px 0px;
    padding: 15px;
  }
  .footer-text {
    text-align: center;
    margin: 4px 0px;
  }
  .projects-banner {
    margin-top: 20px;
  }
  .visual-heading {
    font-size: 48px;
  }
  .visual-paragraph {
    font-size: 14px;
  }
  .visual-banner-container > .visual-banner-img {
    height: 350px;
  }
  .card-box .card-container {
    height: 300px;
    margin-bottom: 0px;
  }
  .card-box .card-box-distribution {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .card-box .card-padding {
    padding: 16px;
  }
  .card-box .title {
    font-size: 24px;
  }
  .card-box .description {
    font-size: 14px;
  }
  .card-box .view-more-btn {
    margin-top: 8px;
  }
  .overlay {
    width: 100%;
  }
  .project-image .image-style {
    min-height: auto;
  }
  .project-heading-div {
    align-items: flex-start;
  }
  .project-heading {
    font-size: 40px;
  }
  .detail-section {
    padding: 60px 0px 0px 0px;
    border-radius: 40px;
  }
  .about-box {
    margin-top: 30px;
    padding: 0px 30px;
  }
  .about-text {
    font-size: 16px;
  }
  .nav-circle {
    right: 17px;
    top: 21px;
  }
}
