.x-col-lg-6 {
  max-width: 50%;
  flex: 0 0 50%;
}
.x-row {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}
.display-flex {
  display: flex;
}
.x-3 {
  width: 25%;
  flex: 0 0 25%;
}
.x-4 {
  width: 33.333333%;
  flex: 0 0 33.333333%;
}
.x-6 {
  max-width: 50%;
  flex: 0 0 50%;
}
.x-12 {
  max-width: 100%;
  flex: 0 0 100%;
}

.x-3,
.x-4,
.x-6,
.x-12 {
  padding-left: 8px;
  padding-right: 8px;
  width: 100%;
}

.no-padding-web {
  padding-left: 0px;
  padding-right: 0px;
}

--------------------------------------------------- body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.contact {
  color: #d9d9d9;
  padding-top: 60px;
}
.web-form {
  background-color: #000000e0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-submit {
  background-color: #00d1a1;
  padding: 10px 50px;
  border-radius: 5px;
  color: #024d3b;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  outline: none;
  border: none;
}
.loader-container {
  background-color: white;
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(14px) !important;
  -webkit-backdrop-filter: blur(14px) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.data-loader {
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #00d1a1;
  width: 74px;
  height: 74px;
  animation: spin 2s linear infinite;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
.loader {
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #00d1a1;
  width: 74px;
  height: 74px;
  animation: spin 2s linear infinite;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.visible-overlay {
  z-index: 2001 !important;
  opacity: 1 !important;
  transition: 0.3s !important;
}
.overly {
  position: fixed;
  z-index: -3;
  opacity: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(14px) !important;
  -webkit-backdrop-filter: blur(14px) !important;
  transition: 0.3s !important;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.label {
  color: #d9d9d9;
  position: absolute;
  top: -52%;
}
.label-message {
  color: #d9d9d9;
  position: absolute;
  top: -23%;
}

.justify {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 34px 0px;
}

.input-form {
  width: 350px;
  padding: 15px 10px !important;
  height: auto !important;
  background-color: #151d28 !important;
  border: 1px solid #30363d !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  border-radius: 0.25rem;
}
.input-message {
  width: 700px;
  padding: 15px 10px !important;
  height: auto !important;
  background-color: #151d28 !important;
  border: 1px solid #30363d !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  border-radius: 0.25rem;
}

#overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
}

#text {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: white;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

body.no-scroll {
  overflow: hidden;
}
